/* top menu bar */

.top-menu {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 10;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 1rem
}

.top-menu .top-menu-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem
}

.top-menu .top-menu-inner .header-search {
  height: 2.5rem;
  width: 66.666667%;
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
  --tw-bg-opacity: 0.05
}

.top-menu form {
  position: relative;
  margin: 0px;
  height: 100%;
  padding: 0px
}

.top-menu form svg {
  position: absolute;
  left: 0.5rem;
  height: 100%;
  width: 1.25rem;
  fill: #94a3b8
}

.top-menu form input {
  margin: 0px;
  height: 100%;
  width: 100%;
  background-color: transparent;
  padding-left: 2.25rem
}

.top-menu .profile-dropdown {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem
}

.top-menu .profile-dropdown .header-btn {
  display: inline-flex;
  height: 2.5rem;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  border-radius: 0.125rem;
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
  padding: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  line-height: 1;
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity))
}

.top-menu .profile-dropdown .header-btn > span {
  display: flex;
  gap: 0.5rem
}

.top-menu .profile-dropdown .header-btn > span svg {
  height: 1.25rem;
  width: 1.25rem;
  fill: #fff
}

.top-menu .profile-dropdown .header-btn > span span {
  display: flex;
  height: 1.5rem;
  width: 1.5rem;
  flex-direction: column;
  justify-content: center;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity))
}

.top-menu .profile-dropdown .menu-wrap {
  width: 16rem;
  border-radius: 0.5rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 0.5rem;
  --tw-drop-shadow: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.top-menu .profile-dropdown .menu-wrap .who-me span:first-child {
  display: flex;
  height: 2.5rem;
  width: 2.5rem;
  flex-direction: column;
  justify-content: center;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
  text-align: center;
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.top-menu .profile-dropdown .menu-wrap hr {
  margin: 0.5rem;
  height: 1px;
  border-width: 0px;
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity))
}

.top-menu .profile-dropdown .menu-wrap a {
  display: flex;
  gap: 1rem;
  padding: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity))
}

.top-menu .profile-dropdown .menu-wrap a:hover {
  border-radius: 0.375rem;
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity))
}

.top-menu .profile-dropdown .menu-wrap a svg {
  height: 1rem;
  width: 1rem;
  fill: #111827
}

.top-menu .profile-dropdown .menu-wrap a:hover svg {
  fill: #1d4ed8
}

.top-menu .profile-dropdown .menu-wrap .secondary span {
  --tw-text-opacity: 1;
  color: rgb(120 113 108 / var(--tw-text-opacity))
}