/* organizations on front page */

.trending-organizations {
  padding-left: 1rem;
  padding-right: 1rem
}

.trending-organizations > h3 {
  margin-bottom: 1.5rem;
  margin-top: 2.25rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity))
}

.trending-organizations .orgs {
  margin-bottom: 0.75rem;
  display: flex;
  flex-direction: column
}

.trending-organizations .orgs a {
  flex: 1 1 1px;
  flex-shrink: 1 0;
  margin-bottom: 0.75rem;
  display: flex;
  gap: 1rem;
  border-radius: 0.375rem;
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
  padding: 0.75rem;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.trending-organizations .orgs a:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.trending-organizations .orgs a .avatar {
  display: flex;
  height: 3rem;
  width: 3rem;
  flex-shrink: 0;
  overflow: hidden;
  border-radius: 9999px
}

.trending-organizations .orgs a .org-info h4 {
  display: block;
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity))
}

.trending-organizations .orgs a .org-info p {
  display: block;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(161 161 170 / var(--tw-text-opacity))
}

.trending-organizations .orgs a .org-info p.metrics {
  margin-top: 0.25rem;
  display: block;
  font-size: 0.75rem;
  line-height: 1rem;
  --tw-text-opacity: 1;
  color: rgb(82 82 91 / var(--tw-text-opacity))
}

/* topics on front page */

.trending-topics {
  padding-left: 1rem;
  padding-right: 1rem
}

.trending-topics > h3 {
  margin-bottom: 1.5rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity))
}

.trending-topics .topics {
  margin-bottom: 1rem
}