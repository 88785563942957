/* front page feed items */

.content-feed {
  margin-top: 0px
}

.feed-item {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  max-width: 42rem;
  border-radius: 0.125rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.feed-item .cover {
  height: 250px;
  overflow: hidden;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem
}

.feed-item .cover .img-wrap {
  height: 250px;
  overflow: hidden
}

.feed-item .cover .img-wrap img {
}

.feed-item .byline {
  display: flex;
  gap: 1rem;
  padding: 1rem
}

.feed-item .byline .avatar-group {
  position: relative;
  display: flex
}

.feed-item .byline .author {
  font-size: 0.875rem;
  line-height: 1.25rem
}

.feed-item .byline .author a {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
  text-decoration-line: underline
}

.feed-item .byline .author span {
  font-size: 0.75rem;
  line-height: 1rem
}

.feed-item .byline .avatar-group .avatar {
  height: 2.5rem;
  width: 2.5rem;
  overflow: hidden
}

.feed-item .byline .avatar-group .avatar img {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 9999px
}

.feed-item .byline .avatar-group .a-avatar {
  position: absolute;
  right: -10px;
  display: flex;
  height: 1.25rem;
  width: 1.25rem;
  flex-direction: column;
  justify-content: center;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
  text-align: center;
  font-size: 0.75rem;
  line-height: 1rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.feed-item .byline .avatar-group .b-avatar {
  display: flex;
  height: 2.5rem;
  width: 2.5rem;
  flex-direction: column;
  justify-content: center;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
  text-align: center;
  font-size: 0.75rem;
  line-height: 1rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.feed-item .body {
  padding-left: 1rem;
  padding-right: 1rem
}

.feed-item .body h4 {
  margin-bottom: 0.75rem;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5rem
}

.feed-item .body p {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem
}

.feed-item .body .tags {
  margin-top: 0.5rem
}

.feed-item .body .tags a {
  margin-right: 1rem;
  display: inline-block;
  font-size: 0.75rem;
  line-height: 1rem;
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity))
}

.feed-item .body .tags a:hover {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity))
}

.feed-item .footer {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 0.5rem;
  display: flex;
  gap: 1rem;
  border-top-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.75rem;
  line-height: 1rem;
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity))
}

.feed-item .footer a + a {
  margin-left: 1rem
}

.feed-item .footer a:hover {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity))
}

.feed-item .footer svg {
  margin-right: 0.25rem;
  display: inline-block;
  height: 1rem;
  width: 1rem
}